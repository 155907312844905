import React, { useState, useEffect } from 'react';
import '../../App.css';
import { collection, addDoc, updateDoc, deleteDoc, getFirestore, doc } from 'firebase/firestore';
import { db } from '../../firebase-config';

function Modal({ isOpen, onClose, formLocation, documentData }) {
  const [formData, setFormData] = useState({ product: '', price: '', cost: '', time: '', id: '' });
  const firestore = getFirestore();

  useEffect(() => {
    if (documentData && formLocation !== "Create") {
      setFormData({
        product: documentData.product || '',
        price: documentData.price || '',
        cost: documentData.cost || '',
        time: documentData.time || '',
        id: documentData.id || ''
      });
    } else if (formLocation === "Create") {
      setFormData({ product: '', price: '', cost: '', time: '', id: '' });
    }
  }, [documentData]);

  if (!isOpen) return null;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (event) => {
    formLocation === "Create" ? handleCreate(event) : handleUpdate(event, documentData);
  };

  const handleCreate = async (event) => {
    event.preventDefault();
    try {
      await addDoc(collection(db, 'products'), {
        name: formData.product,
        price: formData.price,
        cost: formData.cost,
      });
    } catch (error) {
      console.error('Error adding document: ', error);
    }
    window.location.reload();
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    console.log(formData);

    const docRef = doc(firestore, "products", documentData.id);

    try {
      await updateDoc(docRef, {
        product: formData.product,
        price: formData.price,
        cost: formData.cost,
        time: formData.time
      });
      console.log("Document successfully updated!");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
    window.location.reload();
  };

  const handleDelete = async (documentId) => {
    const firestore = getFirestore();  
    const docRef = doc(firestore, "products", documentId); 
  
    try {
      await deleteDoc(docRef);
      console.log("Document successfully deleted!");
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
    window.location.reload();
  };
  

  return (
    <div className="modal-overlay">
      <div className="modal">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>{formLocation} Product</h2>{formLocation !== "Delete" ? (
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="product">Product:</label><br />
              <input type="text"
                id="product"
                name="product"
                value={formData.product}
                onChange={handleChange}
                required />
            </div>
            <div>
              <label htmlFor="price">Price:</label><br />
              <input type="number"
                id="price"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required />
            </div>
            <div>
              <label htmlFor="cost">Cost:</label><br />
              <input
                type="number"
                id="cost"
                name="cost"
                value={formData.cost}
                onChange={handleChange}
                required />
            </div>
            <div>
              <label htmlFor="time">Time:</label><br />
              <input type="number"
                id="time"
                name="time"
                value={formData.time}
                onChange={handleChange}
                required />
            </div>
            <button type="submit important">Submit</button>
          </form>) : (
          <div>
            <p>Are you sure you want to delete this product? <br/> {documentData.product}, ${documentData.price}</p>
            <button className="important" onClick={() => handleDelete(documentData.id)}>Delete</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Modal;