import '../App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { GrMailOption } from "react-icons/gr";
import { GrDocumentPdf } from "react-icons/gr";
import { GrGift } from "react-icons/gr";

function About() {
    return (
        <div className="about">
          <div className="home_banner">
            <div className="home_banner_text_container">
              <h1 className="home_banner_text">Hookers Welcome</h1>
              <p>not your grandmother's crochet... <br/> but if she's fun she'd approve.</p>
              <button className="important"><Link to="/store/items" className="link">Get Started<IoArrowForwardCircleOutline /></Link></button>
            </div>
        </div>

        <div className="home_section section1">
          <div className="home_section1_text1_container">
            <h2>Find the crochet project for YOU</h2>
            <p>Small functional items, blankets, stuffed animals and more. There's something for every hooker.</p>
          </div>
          <div className="home_section1_buttons_container">
            <button className="important"><Link to="/store/patterns" className="link">Patterns <GrDocumentPdf /></Link></button>
            <button className="important"><Link to="/store/items" className="link">Finished Items <GrGift /></Link></button>
          </div>
        </div>

        <div className="home_section section2">
          <div className="home_section2_text">
            <h2>Hey there, I'm Kayleigh</h2>
            <p>I started crochet a few years ago and I was <span>hooked</span> right away. I'm entirely self taught from random diagrams on the internet and since then, I basically made up my own set of rules. In the past few years, I've taught crochet, started clubs, and sold items in craft fairs. I like making patterns and hate following them. When I'm not crocheting, I'm a software developer and I coded this website myself.</p>
            <button className="important"><Link to="/contact" className="link">Contact Me <GrMailOption /></Link></button>

          </div>

          <div className="home_section2_image">
            <img src={`${process.env.PUBLIC_URL}/1.png`} alt="" />
          </div>
        </div>
        </div>
    )
}

export default About;