import '../../App.css';
import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import { auth } from '../../firebase';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { db } from '../../firebase-config';

function Admin() {
    const [user, setUser] = useState(null);
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdateCreate, setIsUpdateCreate] = useState(null);
    const [updateData, setUpdateData] = useState({ product: '', price: '', cost: '', time: '', id: '' });

    useEffect(() => {

        const fetchProductsData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "products"));
                const itemsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setItems(itemsList);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchProductsData();

        const unsubscribe = auth.onAuthStateChanged(setUser);
        return () => unsubscribe();
    }, []);

    const handleOpenModal = () => {
        setIsModalOpen(true);
        setIsUpdateCreate("Create");
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleUpdate = async (item) => {
        setIsModalOpen(true);
        setIsUpdateCreate("Update");
        setUpdateData({ product: item.name, price: item.price, cost: item.cost, time: item.time, id: item.id });
    };

    const handleDelete = async (item) => {
        setIsModalOpen(true);
        setIsUpdateCreate("Delete");
        setUpdateData({ product: item.name, price: item.price, cost: item.cost, time: item.time, id: item.id });
    };

    return (
        <div className="admin">
            {user ? (<div>
                <h1>Welcome Kayleigh</h1>

                <button className="important">Suspend Orders</button>

                <h2>Orders</h2>
                <div className="admin_table">
                    <div className="admin_table_heading">
                        <div className="order">
                            <p>Date</p>
                            <p>Name</p>
                            <p>Item</p>
                            <p>Price</p>
                            <p>Status</p>
                            <p>Actions</p>
                        </div>
                    </div>

                    <div className="order">
                        <p>9.7.2024</p>
                        <p>Katie H</p>
                        <p>Small Cactus</p>
                        <p>$20.00</p>
                        <p>New</p>
                        <div className="order_actions">
                            <button>Email</button>
                            <button>Cancel</button>
                        </div>
                    </div>

                    <div className="order">
                        <p>9.7.2024</p>
                        <p>Katie H</p>
                        <p>Small Cactus</p>
                        <p>$20.00</p>
                        <p>New</p>
                        <div className="order_actions">
                            <button>Email</button>
                            <button>Cancel</button>
                        </div>
                    </div>
                </div>

                <h2>Products</h2>
                <table className="admin_table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Price</th>
                            <th>Cost</th>
                            <th>Time</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(item => (
                            <tr key={item.id}>
                                <td>{item.name}</td>
                                <td>{item.description}</td>
                                <td>${parseFloat(item.price).toFixed(2)}</td>
                                <td>${parseFloat(item.cost).toFixed(2)}</td>
                                <td>{item.time} hours</td>
                                <td>
                                    <button onClick={() => handleUpdate(item)}>Edit</button>
                                    <button onClick={() => handleDelete(item)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>


                <button onClick={handleOpenModal} className="important">Add a Product</button>

                <Modal isOpen={isModalOpen} onClose={handleCloseModal} formLocation={isUpdateCreate} documentData={updateData} />

                <h2>Events</h2>
                <button>Add an Event</button>

                <h2>Blog Posts</h2>
                <button>Add a Blog Post</button></div>
            ) : (
                <div><p>You are not logged in.</p></div>
            )}
        </div>
    )
}

export default Admin;