import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// Your Firebase configuration object
const firebaseConfig = {
    apiKey: "AIzaSyDJtmowTAo_nxWXP69Povzhu0E79E7Hr40",
    authDomain: "crochet-website-160c5.firebaseapp.com",
    projectId: "crochet-website-160c5",
    storageBucket: "crochet-website-160c5.appspot.com",
    messagingSenderId: "706326571376",
    appId: "1:706326571376:web:5c498f876df302a937c7b5",
    measurementId: "G-35MBQR3PZJ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Export the Firebase auth instance
export const auth = firebase.auth();
export default firebase;
