import '../App.css';

function Events() {
    return (
        <div className="events">
            <h1>Events</h1>
        </div>
    )
}

export default Events;