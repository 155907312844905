import '../src/App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

function Header() {
    return (

        <header className="header">
            <div className="header_nav_menu_container">
                <button><Link to="/store/items">store</Link></button>
                <button><Link to="/events">events</Link></button>
                <button><Link to="/blog">blog</Link></button>
            </div>

            <div className="header_logo_container">
                <Link to="/"><img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" className="header_logo" /></Link>
            </div>

            <div className="header_contact_menu_container">
                <button><span className="material-symbols-outlined">mail</span></button>
                <button><span className="material-symbols-outlined">shopping_cart</span></button>
                <button className="important"><Link to="/contact" className="link">contact</Link></button>

            </div>

        </header>

    )
}

export default Header;
