import '../src/App.css';
import React, { useState, useEffect } from 'react';

import { TiSocialInstagramCircular } from "react-icons/ti";
import { TiSocialPinterestCircular } from "react-icons/ti";
import { TiSocialYoutubeCircular } from "react-icons/ti";
import { TiSocialFacebookCircular } from "react-icons/ti";
import { IoIosArrowUp } from "react-icons/io";

function Footer() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className={`footer ${isOpen ? 'open' : ''}`}>
            <div className="left-align">
                <button><TiSocialInstagramCircular /></button>
                <button><TiSocialPinterestCircular /></button>
                <button><TiSocialYoutubeCircular /></button>
                <button><TiSocialFacebookCircular /></button>
            </div>
            <button className="main-menu-btn" onClick={toggleMenu}><IoIosArrowUp /></button>
            <div className="menu-content">
                <ul>
                    <li><a href="#services">Home</a></li>
                    <li><a href="#store">Store</a></li>
                    <li><a href="#about">Events</a></li>
                    <li><a href="#contact">Blog</a></li>
                    <li><a href="#contact">Contact</a></li>
                </ul>
            </div>
        </div>
    )
}

export default Footer;