import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase-config'; // Adjust the import based on your file structure

function Patterns(props) {
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "patterns"));
                const itemsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setItems(itemsList);
            } catch (error) {
                setError(error.message);
            } 
        };

        fetchData();
    }, []);

    // if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div>
            <ul>
                {items.map(item => (
                    <li key={item.id}>
                        <h2>{item.name}</h2>
                        <p>{item.description}</p>
                        <p>Price: ${item.price}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Patterns;
