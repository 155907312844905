import React, { useEffect } from 'react';
import { NavLink, Route, Routes, useNavigate } from 'react-router-dom';
import Items from './Items';
import Patterns from './Patterns';
import '../../App.css';

function Store() {

    return (
        <div className="store">
            <h1>Store</h1>

            <div className="store_tabs">
                <NavLink to="items" className="tab-link">Physical Items</NavLink>
                <NavLink to="patterns" className="tab-link">Patterns</NavLink>
                <NavLink to="custom" className="tab-link">Custom Orders</NavLink>
            </div>
        
            <div className="store_tab_content">
                <Routes>
                    <Route path="/" element={<div>Please select a tab.</div>} />
                    <Route path="items" element={<div><Items collection={"products"}/></div>} />
                    <Route path="patterns" element={<div><Patterns collection={"patterns"}/></div>} />
                    <Route path="custom" element={<div>This is the Custom Orders content.</div>} />
                </Routes>
            </div>
        </div>
    );
}

export default Store;
